<script lang="ts" setup>
import needleIcon from '~/assets/img/game/needle.png'

const props = defineProps({
  endurance: {
    type: Number,
    default: 0,
  },
})

const progress = computed(() => {
  const min = -134
  const max = 114

  const result = min + (props.endurance / 100) * (max - min)
  return `rotate(${result}deg)`
})
</script>

<template>
  <div class="temperature-sensor">
    <div class="temperature-sensor__gradient" />
    <img :src="needleIcon" alt="needle icon" class="needle">
  </div>
</template>

<style lang="scss">
.temperature-sensor {
  width: 64px;
  height: 64px;
  background: url(~/assets/img/game/temperature-sensor.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;

  &__value {
    color: #fff;
    font-family: $font-chango;
    font-size: 11.583px;
    font-weight: 400;
    line-height: 32px;
  }

  .needle {
    width: 5px !important;
    height: 22px !important;
    margin-top: -13px;
    transform-origin: 50% 80%;
    transform: v-bind(progress);
    transition: transform 0.3s ease-in-out;
  }
}
</style>
