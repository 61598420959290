<script lang="ts" setup>
const tasksStore = useTasksStore()

const active = ref(false)

const points = computed(() => tasksStore.state.claimedTasks[tasksStore.state.claimedTasks?.length - 1]?.rewardPoints ?? 0)

watch(() => tasksStore.state.isNotify, (n) => {
  if (n) {
    active.value = true
    setTimeout(() => {
      tasksStore.state.isNotify = false
      active.value = false
    }, 3000)
  }
})
</script>

<template>
  <div v-if="active" class="task-notify active-notify">
    You got +{{ points }} <i-app-j-points />
  </div>
</template>

<style lang="scss">
.task-notify {
  position: fixed;
  width: 90%;
  height: 74px;
  bottom: -74px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: rgb(18 27 31);
  font-family: $font-secondary;
  font-size: 15px;
  font-weight: 600;

  svg {
    width: 20px;
    height: 20px;
  }

  &.active-notify {
    animation: TaskNotify 3s ease-in-out;
  }

  @keyframes TaskNotify {
    0% {
      transform: translate(-50%, 0);
    }
    10% {
      transform: translate(-50%, -200px);
    }
    80% {
      transform: translate(-50%, -200px);
    }
    90% {
      transform: translate(-50%, 0);
    }
  }
}
</style>
