<script lang="ts" setup>
import { matClose } from '@quasar/extras/material-icons'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  top: {
    type: Number,
    default: 20,
  },
})
const emit = defineEmits(['close'])
function close() {
  emit('close')
}

watch(() => props.show, () => {
  const el = document.querySelector('.main-tabs')
  if (el) {
    el.classList.toggle('hide-tabs')
  }
})
</script>

<template>
  <div class="additional-panel" :class="{ 'active-panel': show }">
    <q-icon class="close-icon" :name="matClose" @click="close" />

    <div v-if="$slots.title" class="additional-panel__title">
      <slot name="title" />
    </div>
    <slot />
  </div>
</template>

<style lang="scss">
.hide-tabs {
  bottom: -124px !important;
}
.additional-panel {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 100vh;
  left: 0;
  background: #28414c;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 32px 40px;
  transition: 0.3s;
  z-index: 999;

  &__title {
    color: #fff;
    text-align: center;
    font-family: $font-monrope;
    font-size: 19px;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 24px;
  }

  &.active-panel {
    top: v-bind('`${top}px`');
  }

  .close-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $gray;
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;
    z-index: 1;
    color: #fff;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
