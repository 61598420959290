<script lang="ts" setup>
import gsap from 'gsap'
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice } from '~/utils'

const emit = defineEmits(['gameHandler', 'tabHandler'])

const gameStore = useGameStore()

const tweened = reactive({
  number: Number(gameStore.userPoints) || 0,
})

watch(() => gameStore.userPoints, (p) => {
  gsap.to(tweened, { duration: 2.5, number: Number(p) || 0 })
})
</script>

<template>
  <event-container v-if="gameStore.state.events.length !== 0" :event="gameStore.state.events[0]" @tab-handler="(t) => emit('tabHandler', t)" />
  <div class="pumpjack-home">
    <div class="home-top">
      <game-user-avatar is-avatar />

      <div class="home-top__username">
        {{ gameStore.state.tgUserData?.user?.username || 'Anonymous' }}
      </div>
      <div class="home-top__points">
        <img :src="jpointIcon" alt="jpoints icon">
        {{ formatPrice(tweened.number, 2, 2) }}
      </div>
    </div>

    <game-view @game-handler="emit('gameHandler')" @tab-handler="(t) => emit('tabHandler', t)" />
  </div>
</template>
