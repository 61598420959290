<script setup lang="ts">
import { capitalize } from 'lodash-es'
import communityIcon from '~/assets/img/icon/community.svg?raw'

const tabs = [{
  name: 'JPool Holders Club',
},
{
  name: 'Community',
  icon: communityIcon,
},
]
const tab = ref(tabs[0]!.name)
</script>

<template>
  <div class="exchange-tabs wallet-tabs">
    <q-tabs v-model="tab">
      <q-tab v-for="t in tabs" :key="t.name" :name="t.name">
        <i v-html="t.icon" />
        {{ capitalize(t.name) }}
      </q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" animated class="wallet-tab-panel">
      <q-tab-panel name="JPool Holders Club">
        <game-wallet-member />
      </q-tab-panel>

      <q-tab-panel name="Community">
        <game-wallet-community />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<style lang="scss">
.wallet-tabs {
  position: relative;
  background-color: transparent;
  z-index: 11;

  .q-tabs {
    padding: 0 20px;
  }

  .q-tab-panels {
    padding: 38px 0 0;
  }

  .q-tab__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}
.wallet-tab-panel {
  background-color: transparent !important;
}
</style>
