<script lang="ts" setup>
const gameStore = useGameStore()

function reloadApp() {
  window.location.reload()
}
</script>

<template>
  <transition name="fade">
    <div v-if="gameStore.state.loading" class="app-preloder">
      <q-spinner
        color="white"
        size="100px"
        :thickness="2"
      />
      Loading
    </div>
  </transition>
  <div v-if="gameStore.gameFail" class="app-fail">
    An error has occurred! Please reload the application or try again later

    <q-btn unelevated @click="reloadApp">
      reload
    </q-btn>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.app-preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  z-index: 999;
  background-color: $game-bg;
  color: #fff;
  font-size: 26px;
  font-family: $font-secondary;
}

.app-fail {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 20vh 20px 0;
  z-index: 999;
  background-color: rgb(26 42 47 / 90%);
  color: #fff;
  font-size: 16px;
  font-family: $font-secondary;

  .q-btn {
    background-color: $gray;
    color: #fff;
  }
}
</style>
