<script lang="ts" setup>
import { Notify } from 'quasar'
import { useGameService } from '~/services'
import { withZero } from '~/utils'

const emit = defineEmits(['stopTower'])

const gameStore = useGameStore()
const { getTowerRewards } = useGameService()

const result = ref('00:00:00')
const endFarming = ref(false)
const disabled = ref(false)

const gameConfig = computed(() => gameStore.gameConfig?.tower)
const farmingTime = computed(() => gameConfig.value?.farmingTime ?? 0)
const farmingTimeToHour = computed(() => farmingTime.value / 3600)

let timeRemainingInterval
function getTimeRemaining() {
  const hoursToAdd = farmingTime.value * 1000
  const endTime = gameStore.towerState.startFarmingAt + hoursToAdd
  const now = Date.now()
  const timeRemaining = endTime - now

  if (timeRemaining <= 0) {
    clearInterval(timeRemainingInterval)
    endFarming.value = true
    emit('stopTower', true)
    return '00:00:00'
  }

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60))
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

  result.value = `${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}`
}

const farmingGallons = computed(() => farmingTimeToHour.value * (gameConfig.value?.gallonsPerHour ?? 0))

function gallonToBarrels(gallons: number | string) {
  return Math.floor(Number(gallons) / 42)
}

async function claimRewards() {
  try {
    disabled.value = true
    if (!gameStore.state.gameUserData?.id) {
      throw new Error('Game user id is undefined')
    }
    await getTowerRewards(gameStore.state.gameUserData.id)
    const barrelsWithReward = Number(gameStore.state.gameUserData.barrels) + Number(farmingGallons.value)
    gameStore.state.gameUserData.barrels = barrelsWithReward
    gameStore.towerState.startFarmingAt = Date.now()
    emit('stopTower', false)
    Notify.create({
      message: 'Farmed barrels claimed',
      type: 'positive',
      position: 'top',
    })
  } catch (err) {
    console.log(err)
  } finally {
    disabled.value = false
    endFarming.value = false
  }
}

watch(() => gameStore.towerState.startFarmingAt, (s) => {
  if (s !== 0) {
    getTimeRemaining()

    timeRemainingInterval = setInterval(() => {
      getTimeRemaining()
    }, 1000)
  }
}, { immediate: true })

onUnmounted(() => {
  clearInterval(timeRemainingInterval)
})
</script>

<template>
  <q-skeleton v-if="gameStore.state.loading" width="110px" height="24px" class="farming-timer" />
  <div v-else class="farming-timer">
    {{ result }}
  </div>

  <q-dialog v-model="endFarming" class="farming-dialog" persistent>
    <q-card>
      <q-card-section>
        <div class="column">
          The oil pump has been operating for {{ farmingTimeToHour }} hour(s) and has produced {{ gallonToBarrels(farmingGallons) }}
          barrel(s)! Claim your oil to keep the pump running.
          <q-skeleton v-if="disabled" width="100%" height="42px" class="q-mt-md" />

          <q-btn v-else unelevated class="q-mt-md" @click="claimRewards">
            Claim
          </q-btn>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.farming-timer {
  position: absolute;
  bottom: 10px;
  font-family: $font-secondary;
  background-color: #8c9ca764;
  width: 110px;
  padding: 0 0 0 20px;
  text-align: left;
  border-radius: 20px;
  color: #fff;
  letter-spacing: 1px;
  z-index: 3;

  .q-skeleton {
    border-radius: $border-radius;
  }
}

.farming-dialog {
  font-family: $font-secondary;
  font-weight: 500;

  .q-btn {
    color: #000 !important;
    background-color: $secondary;
  }
}
</style>
