export function generateColor(text) {
  const colors = [
    '#FFAD00', '#FF6900', '#F45D22', '#E7B90B',
    '#A52A2A', '#BD5D38', '#008B8B', '#6082B6',
    '#8A2BE2', '#3CB371', '#6A5ACD', '#708090',
    '#FF6347', '#FF4500', '#DA70D6', '#32CD32',
    '#20B2AA', '#FFD700', '#B22222', '#4682B4',
  ]
  let hash = 0
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }
  return colors[Math.abs(hash % colors.length)]
}
