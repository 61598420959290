<script lang="ts" setup>
import { Notify } from 'quasar'
import barrelIcon from '~/assets/img/game/barrel.png'
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { roundToTwoSignificantDigits } from '~/utils'
import { Analytics } from '~/utils/analytics'

const { sellUserBarrels } = useGame()
const gameStore = useGameStore()

const inputRef = ref(null)
const sellDialog = ref(false)
const sellData = ref()

function onlyNumber(e: any) {
  const keyCode = e.keyCode ? e.keyCode : e.which
  if ((keyCode < 48 || keyCode > 57)) {
    e.preventDefault()
  }
}

const barrel = computed(() => gameStore.gameConfig?.tower?.barrel ?? 42)

const allBarrels = computed(() => Math.floor(Number(gameStore.state.gameUserData?.barrels) / barrel.value))

const barrelsToSell = ref(allBarrels.value)

function setMax() {
  if (allBarrels.value !== 0) {
    barrelsToSell.value = allBarrels.value
  }
}

async function sell() {
  const price = gameStore.state.exchangePrices[gameStore.state.exchangePrices.length - 1]?.price
  if (!price) {
    return
  }

  if (!barrelsToSell.value || Number(barrelsToSell.value) <= 0) {
    Notify.create({
      type: allBarrels.value === 0 ? 'negative' : 'warning',
      message: allBarrels.value === 0 ? 'No barrels to sell' : 'Enter the number of barrels to sell',
      position: 'top',
    })
    return
  }
  if (Number(allBarrels.value) < Number(barrelsToSell.value)) {
    Notify.create({
      type: 'negative',
      message: 'You don`t have enough barrels to sell',
      position: 'top',
    })
    return
  }

  try {
    await sellUserBarrels(Number(barrelsToSell.value))
    const data = gameStore.state.sellHistory[0]?.jpoints ?? 0
    sellData.value = roundToTwoSignificantDigits(data)
    sellDialog.value = true
    barrelsToSell.value = allBarrels.value
    Analytics.track({
      event: 'Sell',
      userData: {
        id: gameStore.state.gameUserData?.id,
        username: gameStore.state.gameUserData?.username,
        telegramId: gameStore.state.gameUserData?.telegramId,
        points: sellData.value,
        price,
      } })
  } catch (err) {
    console.log(err)
  }
}
</script>

<template>
  <div class="exchange-sell">
    <q-input
      ref="inputRef"
      v-model="barrelsToSell"
      placeholder="0"
      outlined
      @keypress="onlyNumber"
    >
      <template #prepend>
        <img :src="barrelIcon" alt="barrel icon">
      </template>
      <template #append>
        <q-btn :disable="allBarrels <= 0" class="max-btn" unelevated @click="setMax">
          max
        </q-btn>
      </template>
    </q-input>
    <q-btn unelevated :loading="gameStore.loadingState.sellLoading" @click="sell">
      sell
    </q-btn>
  </div>

  <q-dialog v-model="sellDialog" class="sell-info-dialog">
    <q-card>
      <q-card-section>
        <div class="column items-center">
          <img :src="jpointIcon" alt="jpoint Icon">
          Oil successfully sold! You have received {{ sellData }} JPoint(s)
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn v-close-popup unelevated>
          ok
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
