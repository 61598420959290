<script lang="ts" setup>
import jpointGif from '~/assets/animation/jpoint-animation.gif'
import jpointIcon from '~/assets/img/jpoint-animation-preview.png'
import hcLogo from '~/assets/img/logo/holders-club-logo-dark.svg'
import jpoolLogo from '~/assets/img/logo/jpool-logo-dark.svg'
import { formatPrice } from '~/utils'

const gameStore = useGameStore()

const router = useRouter()

const userPoints = computed(() => {
  return formatPrice(gameStore.userPoints ?? 0, 2, 2)
})

const platform = computed(() => gameStore.useWebApp?.platform)
const isMobile = computed(() => platform.value === 'ios' || platform.value === 'android')

const jpImage = ref(jpointIcon)

onMounted(() => {
  const image = new Image()
  image.src = jpointGif

  image.onload = () => {
    jpImage.value = jpointGif
  }
})

onUnmounted(() => {
  router.replace({ query: {} })
})
</script>

<template>
  <div class="pumpjack-wallet" :class="{ 'desctop-view': !isMobile }">
    <div class="pumpjack-wallet__top">
      <div class="top-logos">
        <img :src="hcLogo" alt="holders club logo">
        <img :src="jpoolLogo" alt="jpool logo">
      </div>
      <div>
        <game-user-avatar is-avatar />
      </div>
    </div>
    <div class="pumpjack-wallet__jpoints">
      <img class="jpoint" :src="jpImage" alt="jpoint icon">
      <span>
        {{ userPoints }}
      </span>
    </div>

    <game-wallet-tabs />
  </div>
</template>

<style lang="scss">
  .connection-popup {
  padding: 14px 26px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    text-decoration: none;
    font-weight: 400;
    color: $primary;

    &:first-child {
      svg path {
        fill: $secondary;
      }
    }

    i {
      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
