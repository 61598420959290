<script lang="ts" setup>
import { matCheckCircleOutline } from '@quasar/extras/material-icons'

const props = defineProps({
  claimed: Boolean,
  claimIcon: Boolean,
  loading: Boolean,
  disabled: Boolean,
  taskId: String,
})

const emit = defineEmits(['startTask'])

const taskStore = useTasksStore()

function taskHandler() {
  emit('startTask')
}

const claimedTasks = computed(() => taskStore.state.claimedTasks)

const alreadyClaimed = computed(() => !!claimedTasks.value.find(t => t.taskId === props.taskId))
</script>

<template>
  <q-btn
    :class="{ 'claimed-task-btn': claimed || alreadyClaimed || claimIcon }"
    :loading="loading"
    :disable="disabled || alreadyClaimed || taskStore.state.loading"
    unelevated
    @click="taskHandler"
  >
    <template #loading>
      <q-spinner-ios />
    </template>
    <span v-if="!claimed">
      <q-icon v-if="alreadyClaimed || claimIcon" :name="matCheckCircleOutline" />
      <span v-else>
        Start
      </span>
    </span>
    <span v-else>
      <span>
        Claim
      </span>
    </span>
  </q-btn>
</template>

<style lang="scss">
.claimed-task-btn {
  background-color: $secondary !important;
  color: #000 !important;
  min-width: 78px;
  width: 78px;
}
</style>
