<script lang="ts" setup>
import { LottieAnimation } from 'lottie-web-vue'
import oilRickAnimation from '~/assets/animation/oil-rick-animation.json'
import { TOWER_STOP_PER_SECOND } from '~/config/game'

const props = defineProps({
  clicks: {
    type: Number,
    default: 0,
  },
  stopTower: {
    type: Boolean,
    default: false,
  },
})

const gameStore = useGameStore()

const { calculateSpeed } = useGame()

const towerEl = ref()

watch(() => props.clicks, () => {
  calculateSpeed(props.clicks)
  towerEl.value?.setSpeed(gameStore.towerState.speed)
})

let towerSpeedInterval
watch(() => gameStore.towerState.speed, (s) => {
  if (s <= 0.4) {
    clearInterval(towerSpeedInterval)
  } else {
    if (!towerSpeedInterval) {
      towerSpeedInterval = setInterval(() => {
        gameStore.towerState.speed -= TOWER_STOP_PER_SECOND
        towerEl.value?.setSpeed(gameStore.towerState.speed)
      }, 1000)
    }
  }
}, { immediate: true })

watch(towerEl, (el) => {
  if (el) {
    towerEl.value?.setSpeed(gameStore.towerState.speed)
  }
}, { immediate: true })

watch(() => props.stopTower, (s) => {
  if (s) {
    towerEl.value?.pause()
  } else {
    towerEl.value?.play()
  }
})

onUnmounted(() => {
  clearInterval(towerSpeedInterval)
})
</script>

<template>
  <lottie-animation
    ref="towerEl"
    :animation-data="oilRickAnimation" :auto-play="true" :loop="true" :speed="gameStore.towerState.speed"
    class="oil-rick-animation"
  />
</template>
