<script lang="ts" setup>
import pumpjackIcon from '~/assets/img/commuity/pumpjack.svg?raw'
import telegramIcon from '~/assets/img/commuity/telegram.svg?raw'
import roundClipIcon from '~/assets/img/icon/round-clip.svg?raw'
import hcMiniLogo from '~/assets/img/logo/hc-mini.svg?raw'
import phantomIcon from '~/assets/img/phantom-icon.svg?raw'
import solflareIcon from '~/assets/img/solflare-icon.svg?raw'
import { HOLDERS_CLUB_LINK } from '~/config'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'linked'])

function close() {
  emit('close')
}

function linked() {
  emit('linked')
}

const gameStore = useGameStore()

const linkedUrl = computed(() => `${HOLDERS_CLUB_LINK}?telegramId=${gameStore.state.gameUserData?.telegramId}`)

const connections = computed(() => {
  return {
    telegram: {
      label: 'Telegram',
      icon: telegramIcon,
      link: linkedUrl.value,
    },
    phantom: {
      label: 'Phantom',
      icon: phantomIcon,
      link: `https://phantom.app/ul/browse/${encodeURIComponent(`${linkedUrl.value}&wallet=Phantom`)}?ref=https%3A%2F%app.jpool.one`,
    },
    solflare: {
      label: 'Solflare',
      icon: solflareIcon,
      link: browseDapp(`${linkedUrl.value}&wallet=Solflare`),
    },
  }
})

function buildUrl(path: string, params: URLSearchParams) {
  return `solflare://ul/${path}?${params.toString()}`
}

function browseDapp(link: string) {
  const params = new URLSearchParams({
    ref: 'https://solflare.com',
  })
  return buildUrl(`v1/browse/${encodeURIComponent(link)}`, params)
}
</script>

<template>
  <additional-panel :show="show" :top="100" @close="close">
    <template #title>
      Linking to the Club
    </template>
    <div class="linked-top">
      <i v-html="pumpjackIcon" />
      <i class="round-clip" v-html="roundClipIcon" />
      <i style="color: #FFCB25" v-html="hcMiniLogo" />
    </div>

    <div class="linked-desc">
      Link my Game and Club accounts to sync JPoint balances
    </div>

    <div class="linked-subtitle">
      Enter with
    </div>

    <div class="linked-actions">
      <q-btn :loading="loading" class="telegram-link" @click="linked">
        <i v-html="connections.telegram.icon" />
        {{ connections.telegram.label }}
      </q-btn>

      <div class="linked-actions__title">
        crypto wallet
      </div>

      <a :href="connections.phantom.link" target="_blank" class="phantom-link">
        <i v-html="connections.phantom.icon" />
        {{ connections.phantom.label }}
      </a>

      <a :href="connections.solflare.link" target="_blank" class="solflare-link">
        <i v-html="connections.solflare.icon" />
        {{ connections.solflare.label }}
      </a>
    </div>
  </additional-panel>
</template>

<style lang="scss">
.linked-top {
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;

  i {
    width: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .round-clip {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.linked-desc {
  color: $gray-4;
  text-align: center;
  font-family: $font-secondary;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 20px 0 30px;
}

.linked-subtitle {
  color: #fff;
  text-align: center;
  font-family: $font-monrope;
  font-size: 19px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 33px;
}

.linked-actions {
  width: 100%;

  &__title {
    color: $gray-3;
    font-family: $font-secondary;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 30px;
    padding: 22px 0 4px 12px;
  }

  a,
  .q-btn {
    position: relative;
    color: $primary;
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
    border-radius: 11px;
    background: #fff;
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .telegram-link {
    i {
      width: 33px;
      height: 33px;
    }
  }

  .phantom-link {
    background-color: #ab9ff2;
    color: #000;

    i {
      width: 32px;
      height: 26px;
    }
  }

  .solflare-link {
    background: linear-gradient(90deg, rgba(252, 64, 47, 0.9) 0%, rgba(255, 170, 18, 0.9) 100%), #fff;
    color: #000;
    margin-top: 13px;

    i {
      width: 38px;
      height: 38px;
    }
  }
}
</style>
