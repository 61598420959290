<script lang="ts" setup>

</script>

<template>
  <div class="game-exchange">
    <game-exchange-top />
    <game-exchange-chart />
    <game-exchange-sell />
    <game-exchange-tabs />
  </div>
</template>
