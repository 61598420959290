export function isClickInsideCircle(event: any, element: HTMLElement) {
  const rect = element.getBoundingClientRect()

  const centerX = rect.left + rect.width / 2
  const centerY = rect.top + rect.height / 2

  const clickX = event.clientX
  const clickY = event.clientY

  const distance = Math.sqrt(
    (clickX - centerX) ** 2 + (clickY - centerY) ** 2,
  )

  const radius = rect.width / 2

  return distance <= radius
}
