<script lang="ts" setup>
import { date } from 'quasar'
import oilIcon from '~/assets/img/game/barrel.png'
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice, roundToTwoSignificantDigits } from '~/utils'

const columns: any = [
  {
    name: 'date',
    label: 'Date',
    field: 'date',
    align: 'left',
    sortable: true,
  },
  {
    name: 'amount',
    label: 'Barrels',
    field: 'amount',
    align: 'right',
    sortable: true,
    sort: (a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10),
  },
  {
    name: 'price',
    label: 'Price',
    field: 'price',
    align: 'right',
    sortable: true,
    sort: (a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10),
  },
  {
    name: 'jpoints',
    label: 'Amount',
    field: 'jpoints',
    align: 'right',
    sortable: true,
    sort: (a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10),
  },
]

const { state } = useGameStore()

function formatDate(timeStamp: string) {
  return date.formatDate(new Date(timeStamp), 'DD.MM.YYYY')
}

function formatAmount(amount: string | number) {
  const formatedAmount = roundToTwoSignificantDigits(amount)
  return formatPrice(formatedAmount)
}

const rows = computed (() => {
  return state.sellHistory?.map((stat) => {
    return {
      date: stat.createdAt,
      amount: stat.amount,
      price: stat.price,
      jpoints: stat.jpoints,
    }
  }) ?? []
})
</script>

<template>
  <div class="game-statistic">
    <q-table
      class="exchange-history-table" flat :rows="rows" :columns="columns" row-key="name"
      hide-no-data
      :rows-per-page-options="[10]"
      :auto-width="true"
    >
      <template #body-cell-date="props">
        <q-td :props="props">
          <div class="text-gray-3">
            {{ formatDate(props.value) }}
          </div>
        </q-td>
      </template>

      <template #body-cell-amount="props">
        <q-td :props="props">
          <div class="barrels-cell">
            <div class="amount">
              {{ formatPrice(props.value) }}
            </div>
            <img :src="oilIcon" alt="oil icon">
          </div>
        </q-td>
      </template>

      <template #body-cell-price="props">
        <q-td :props="props">
          <div class="amount">
            {{ formatAmount(props.value) }}
          </div>
        </q-td>
      </template>

      <template #body-cell-jpoints="props">
        <q-td :props="props">
          <div class="amount-cell">
            <div class="amount">
              {{ formatAmount(props.value) }}
            </div>
            <img :src="jpointIcon" alt="jpoint icon">
          </div>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<style lang="scss">
.game-statistic {
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .exchange-history-table {
    background-color: $game-bg;
    font-family: $font-secondary;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    margin-top: 12px;
    padding: 0 12px;

    table {
      width: 100%;
      table-layout: fixed;
    }

    th {
      color: $gray-2;
    }

    td {
      padding: 7px 8px;

      .amount {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 25%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 24%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 21%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 30%;
    }

    .barrels-cell {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      img {
        width: 15px;
        height: auto;
      }
    }

    .amount-cell {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
