import { defineStore } from 'pinia'
import type { IUserData } from '~/services/holders-club'

export const useMembershipStore = defineStore('credential', () => {
  const state = reactive<MembershipState>({
    userData: undefined,
  })

  return {
    state,
  }
})

export type MembershipState = {
  userData: IUserData | undefined
}
