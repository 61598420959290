import { HOLDERS_CLUB_APY_URL } from '~/config'
import type { TelegramUserData } from '~/store/game'

/**
 *
 * auth: tokenId 0 - default bot token
 * prod: tokenId 1 - production bot token
 * dev: tokenId 2 - development bot token
 */
async function getSessionToken(data: TelegramUserData) {
  const { hash, ...other } = data
  const encodedUser = btoa(JSON.stringify({ ...other, tokenId: 1 }))

  return fetch(`${HOLDERS_CLUB_APY_URL}/auth/telegram`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hash,
      payload: encodedUser,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((err) => {
      throw err
    })
}

export function useHoldersClubService() {
  return {
    getSessionToken,
  }
}

export type IUserData = {
  id: string
  cluster: string
  tier: number
  pointsEarned: number
  pointsClaimed: number
  imageUrl: string
  credentialMint: string
  isVerified: boolean
  isActive: boolean
  createdAt: Date
  referralCode: string
  telegramUsername?: string
  contactInfo: {
    [key: string]: string
  }
}
