<script lang="ts" setup>
import { Notify } from 'quasar'
import { HOLDERS_CLUB_LINK } from '~/config'
import { useHoldersClubService } from '~/services/holders-club'
import { Analytics } from '~/utils/analytics'

const gameStore = useGameStore()

const { getSessionToken } = useHoldersClubService()

const isLinkedPanel = ref(false)
const isWhyPanel = ref(false)

const loadingLink = ref(false)

const isMember = computed(() => gameStore.state.gameUserData?.userId)

const platform = computed(() => gameStore.useWebApp?.platform)
const isMobile = computed(() => platform.value === 'ios' || platform.value === 'android')

const loading = computed(() => gameStore.loadingState.linkedLoading)

async function linkedAction() {
  loadingLink.value = true
  let link = HOLDERS_CLUB_LINK
  try {
    const token = gameStore.token
    if (token) {
      link += `?sessionToken=${token}`
    } else {
      const token = await getSessionToken(gameStore.state.tgUserData!)
      if (token?.refreshToken) {
        link += `?sessionToken=${token?.refreshToken}`
      }
    }
  } finally {
    loadingLink.value = false

    const a = document.createElement('a')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    a.style.display = 'none'
    document.body.appendChild(a)

    setTimeout(() => {
      a.click()
      document.body.removeChild(a)
    }, 100)

    gameStore.token = null
  }
}

async function linkedHandler() {
  try {
    await gameStore.linkedUser()
  } finally {
    isLinkedPanel.value = false
    Notify.create({
      message: 'Holders club member linked',
      type: 'positive',
      position: 'top',
    })
    Analytics.track({
      event: 'Connect Holders Club',
      userData: {
        id: gameStore.state.gameUserData?.id,
        username: gameStore.state.gameUserData?.username,
        telegramId: gameStore.state.gameUserData?.telegramId,
      } })
  }
}
</script>

<template>
  <div v-if="!isMember" class="pumpjack-wallet__connect">
    <div class="connect-title">
      Sync Your JPoints
    </div>
    <div class="connect-descr">
      Link your Game account to your JPool Holders Club account to sync your JPoint balances.
    </div>

    <q-btn unelevated class="connect-why" @click="isWhyPanel = true">
      Why sync?
    </q-btn>

    <div class="connect-actions">
      <q-btn v-if="isMobile" class="connect-btn" @click="isLinkedPanel = true">
        <i-app-clip color="#ffcb25" />
        Link to Holders Club
      </q-btn>
      <q-btn
        v-else class="connect-btn" target="_blank"
        :loading="loading"
        @click="linkedHandler"
      >
        <i-app-clip color="#8BA0AA" class="link-icon" />
        Link to Holders Club
      </q-btn>
    </div>
  </div>
  <div v-else class="pumpjack-wallet__connect">
    <div class="connect-title text-center">
      Welcome to the Club! @<span style="color: #008dcd">{{ gameStore.state.gameUserData?.username }}</span>
    </div>
    <div class="connect-descr">
      The JPoints you've earned in the game will be synced with your Club balance within 24 hours
    </div>
    <div class="connect-actions">
      <q-btn :loading="loadingLink" class="connect-btn" @click="linkedAction">
        Holders Club
      </q-btn>
    </div>
  </div>

  <linked-panel :loading="loading" :show="isLinkedPanel" @close="isLinkedPanel = false" @linked="linkedHandler" />
  <why-panel :show="isWhyPanel" @close="isWhyPanel = false" />
</template>
