import { PUMPJACK_APY__URL } from '~/config'
import type { TelegramUserData } from '~/store/game'

async function getGameConfig(): Promise<GameConfig> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/config`)
    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getAllUsers() {
  const token = import.meta.env.VITE_JWT_TOKEN

  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/user/all?page=1&limit=1000000`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getUserByUsername(username: string): Promise<GameUserData | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/user/${username}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getLeaderboard(userId: string): Promise<LeaderBoard> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/leaderboard/${userId}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getReferrals(userId: string): Promise<GameUserDataWithBonus[]> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/referral/${userId}/users`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function updateTowerData(id: string, data: TowerState) {
  try {
    await fetch(`${PUMPJACK_APY__URL}/game/tower/heat-level/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getTowerData(id: string) {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/tower/heat-level/${id}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getGameStatistic(id: string): Promise<Array<GameUserStatistic> | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/statistic/${id}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getSellHistory(id: string): Promise<Array<SellHistory> | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/game/sell/history/${id}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getExchangePrices(): Promise<ExchangePriceData[]> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/exchange/all`)
    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function login(telegramData: any) {
  try {
    if (!telegramData) {
      return
    }
    const response = await fetch(`${PUMPJACK_APY__URL}/game/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        telegramData,
      }),
    })
    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getEvents(): Promise<GameEvent[] | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/events`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getTasks(): Promise<GameTask[] | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/tasks?filtered=filtered`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function getClaimedTasks(userId: string): Promise<ClaimedTask[] | undefined> {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/tasks/user/${userId}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

async function claimTask(userId: string, taskId: string) {
  try {
    const response = await fetch(`${PUMPJACK_APY__URL}/tasks/claim/${userId}/${taskId}`)

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.message || response.status
      throw new Error(errorMessage)
    }

    return await response.json()
  } catch (err) {
    console.log(err)
    throw new Error(String(err))
  }
}

export function useGameService() {
  const { state } = useGameStore()

  async function updateGamePoints(id?: string, data?: { barrels: number }): Promise<GameUserData | undefined> {
    try {
      const response = await fetch(`${PUMPJACK_APY__URL}/game/user/update/score/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          telegramData: state.tgUserData,
        }),
      })

      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage = errorData.message || response.status
        throw new Error(errorMessage)
      }

      return await response.json()
    } catch (err) {
      console.log(err)
      throw new Error(String(err))
    }
  }

  async function getTowerRewards(id: string) {
    try {
      const response = await fetch(`${PUMPJACK_APY__URL}/game/tower/rewards/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramData: state.tgUserData,
        }),
      })
      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage = errorData.message || response.status
        throw new Error(errorMessage)
      }

      return await response.json()
    } catch (err) {
      console.log(err)
      throw new Error(String(err))
    }
  }

  async function sellBarrels(id?: string, data?: { barrels: number }): Promise<{
    updatedUser?: GameUserData
    lastSell?: SellHistory
  }> {
    try {
      if (!id || !data) {
        throw new Error('No data to update')
      }

      const response = await fetch(`${PUMPJACK_APY__URL}/game/sell/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          telegramData: state.tgUserData,
        }),
      })

      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage = errorData.message || response.status
        throw new Error(errorMessage)
      }

      return await response.json()
    } catch (err) {
      console.log(err)
      throw new Error(String(err))
    }
  }

  async function linkedGameUser(telegramData: TelegramUserData) {
    try {
      const response = await fetch(`${PUMPJACK_APY__URL}/game/user/linked`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramData,
        }),
      })
      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage = errorData.message || response.status
        throw new Error(errorMessage)
      }

      return await response.json()
    } catch (err) {
      console.log(err)
      throw new Error(String(err))
    }
  }

  return {
    login,
    getAllUsers,
    getGameConfig,

    sellBarrels,
    updateGamePoints,
    getUserByUsername,

    getGameStatistic,
    getSellHistory,
    getLeaderboard,
    getReferrals,

    getTowerData,
    updateTowerData,
    getTowerRewards,

    getExchangePrices,

    linkedGameUser,

    getEvents,

    getTasks,
    claimTask,
    getClaimedTasks,
  }
}

export type GameUserData = {
  id: string
  userId: string
  username: string
  telegramId: string
  points: string
  referralPoints: string
  barrels: number
  updatedAt: Date
  createdAt: Date
}

export type GameUserDataWithBonus = GameUserData & {
  bonusPoints: number
}

export type GameUserStatistic = {
  id: string
  userId: string
  amount: number
  updatedAt: Date
  createdAt: Date
}

export type TowerState = {
  heatLevel: number
  lastClickTime: number
  speed: number
  startFarmingAt: number
}

export type ExchangePriceData = {
  timestamp: number
  price: number
}

export type GameConfig = {
  tower: {
    barrel: number
    farmingTime: number
    gallonsPerHour: number
    gallonsPerClick: number
    towerHeatPerClick: number
  }
}

export type SellHistory = {
  userId: string
  amount: number
  jpoints: number
  price: number
  updatedAt: Date
  createdAt: Date
}

export type LeaderBoard = {
  leaderboard: GameUserData[]
  user: {
    position: number
  }
}

export type GameEvent = {
  id: string
  name: string
  description: string
  status: 'active' | 'end' | 'upcoming'
  type: string
  startTime: Date
  endTime: Date
  reward: any
  updatedAt: Date
  createdAt: Date
}

export type TaskType = 'social' | 'onchain' | 'holders_club' | 'other'

export type GameTask = {
  id: string
  title: string
  description?: string
  url?: string
  icon?: string
  rewardPoints: number
  needCheck: boolean
  order: number
  type: TaskType
  isTimeLimited: boolean
  validUntil?: Date
  createdAt: Date
  updatedAt: Date
}

export type ClaimedTask = {
  id: string
  userId: string
  taskId: string
  isCompleted: boolean
  completedAt: Date | null
  rewardPoints: number
  createdAt: Date
  updatedAt: Date
}
