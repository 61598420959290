<script lang="ts" setup>
import { generateColor } from '~/utils'

const props = defineProps({
  username: {
    type: String,
    required: false,
  },
  isAvatar: {
    type: Boolean,
    default: false,
  },
})

const { state, loadingState } = useGameStore()

const userAvatar = computed(() => props.isAvatar && state.profileAvatar)
const isSkeleton = computed(() => loadingState.avatarLoading && props.isAvatar)

const generateUserAvatarBg = computed(() => {
  const user = state?.tgUserData?.user
  if (!user && !props.username) {
    return '#ffcb25'
  }
  const userName = props.username ?? user?.username ?? `${user?.first_name || ''}${user?.last_name || ''}`
  return generateColor(userName)
})

const getUserInitials = computed(() => {
  if (props.username) {
    return props.username.slice(0, 2).toUpperCase()
  }
  const username = state?.tgUserData?.user?.username || 'anonymous'
  return username.slice(0, 2).toUpperCase()
})
</script>

<template>
  <div class="home-top__avatar" :style="{ backgroundColor: generateUserAvatarBg }">
    <q-skeleton v-if="isSkeleton" rounded width="100%" height="100%" type="QAvatar" />
    <img v-else-if="userAvatar" :src="userAvatar" alt="holders club logo" class="home-top__logo">
    <span v-else>{{ getUserInitials }}</span>
  </div>
</template>

<style lang="scss">
.home-top__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  .home-top__logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  span {
    color: #fff;
    font-weight: 600;
  }
}
</style>
