<script lang="ts" setup>
import { matArrowForwardIos } from '@quasar/extras/material-icons'
import discordIcon from '~/assets/img/commuity/discord.svg'
import jpoolIcon from '~/assets/img/commuity/jpool.svg'
import pumpjackIcon from '~/assets/img/commuity/pumpjack.svg'
import telegramIcon from '~/assets/img/commuity/telegram.svg'
import twitterIcon from '~/assets/img/commuity/twitter.svg'
import { COMMUNITY_LINK, JPOOL_COMMUNITY_LINK, JPOOL_DISCORD_LINK, JPOOL_TWITTER_LINK } from '~/config'

const communityLinks = [
  {
    name: 'Pumpjack',
    type: 'Community',
    icon: pumpjackIcon,
    subIcon: telegramIcon,
    link: COMMUNITY_LINK,
  },
  {
    name: 'Jpool',
    type: 'Community',
    icon: jpoolIcon,
    subIcon: telegramIcon,
    link: JPOOL_COMMUNITY_LINK,
  },
  {
    name: 'Jpool',
    type: 'Page',
    icon: jpoolIcon,
    subIcon: twitterIcon,
    link: JPOOL_TWITTER_LINK,
  },
  {
    name: 'Jpool',
    type: 'Server',
    icon: jpoolIcon,
    subIcon: discordIcon,
    link: JPOOL_DISCORD_LINK,
  },
]
</script>

<template>
  <div class="wallet-community">
    <a
      v-for="community in communityLinks"
      :key="community.name"
      :href="community.link"
      target="_blank"
      class="wallet-community__item"
    >
      <div class="main-icon">
        <img :src="community.icon" :alt="`${community.name} icon`">
      </div>
      <span data-name="community-name">{{ community.name }}</span>
      <img data-name="sub-icon" :src="community.subIcon" :alt="`${community.name} icon`">
      <span>{{ community.type }}</span>

      <q-icon :name="matArrowForwardIos" />
    </a>
  </div>
</template>

<style lang="scss">
.wallet-community {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: -10px;

  &__item {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: 600;
    height: 62px;
    border-bottom: 1px solid $primary;
    text-decoration: none;

    .main-icon {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
    }

    span[data-name='community-name'] {
      width: 80px;
      display: block;
      text-align: right;
    }

    img[data-name='sub-icon'] {
      width: 30px;
      height: 30px;
      margin: 0 14px;
    }

    .q-icon {
      margin-left: auto;
      color: $gray-2;
    }
  }
}
</style>
