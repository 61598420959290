<script lang="ts" setup>
import { matAdd, matRemove } from '@quasar/extras/material-icons'
import hcLogo from '~/assets/img/logo/holders-club-logo-dark.svg'
import jpoolLogo from '~/assets/img/logo/jpool-logo-dark.svg'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

function close() {
  emit('close')
}

const staticInfo = [
  {
    title: 'What is the JPool Holders Club?',
    desc: 'The JPool Holders Club is an exclusive membership community within the JPool ecosystem, dedicated to recognizing'
      + ' and rewarding our most committed users. Club members earn JPoints (JP) for various activities and progress through membership'
      + ' tiers, unlocking additional and/or more valuable rewards.',
  },
  {
    title: 'Why should I link my Game account to the Club?',
    desc: 'Once you link your Game account to the Club and sync the JP balances, the JP you have earned in the Game become redeemable.'
      + ' Bring your JP balances into sync, follow JPool and Pumpjack on social media, and stay tuned for important JP announcements to'
      + ' avoid missing out on opportunities!',
  },
]

const expansionInfo = [
  {
    title: 'Why should I collect JP?',
    desc: 'JP is a universal reward unit of the JPool ecosystem. By accumulating JP, you progress through membership tiers in the JPool '
      + 'Holders Club. The more JP you have, the higher your tier. The higher your tier, the more rewards you get and/or the higher their value. '
      + 'In future seasons, your JP might be converted into other opportunities, but more on that later—stay tuned for announcements.',
  },
  {
    title: 'How else can I earn JP?',
    desc: 'Pumpjack is just one way of earning JP. Besides the Game, you can get JP by performing activities in the JPool Holders Club and JPool. '
      + 'For example, if you are a Club member, you can stake SOL in JPool and receive JSOL to farm 10 JP per day for each JSOL you hold in your balance. '
      + 'You can also boost the JP you farm by up to 2x by investing your JSOL in various DeFi opportunities.'
      + '<br>'
      + '<a href="https://app.jpool.one/holders-club/about#JPoints" target="_blank">Learn more</a>',
  },
  {
    title: 'What is JPool?',
    desc: 'JPool is a liquid staking platform that provides a seamless, user-friendly liquid staking experience with a variety of staking options, '
      + 'such as Smart Staking for hassle-free staking through a smart strategy; JStaking for reliable staking with our institutional-grade partner '
      + 'validator; High-Yield Staking for profitable staking through a special high-yield strategy with our partner; and Flex Staking for customizable '
      + 'staking to validators of choice.'
      + '<br><br>'
      + 'JPool also enhances user experience with auxiliary tools, including JPool Insights for tracking and reporting transactions; SVT (Smart Validator Toolkit) '
      + 'for managing validators; and Validator Dashboard for viewing detailed validator metrics. Most committed users can earn additional rewards '
      + 'through the JPool Holders Club and our tap-to-earn game Pumpjack.',
  },
]
</script>

<template>
  <additional-panel :show="show" class="why-panel" @close="close">
    <template #title>
      Why Sync
    </template>

    <div class="why-panel__card">
      <div class="logos">
        <img class="jpool-logo" :src="jpoolLogo" alt="jpool logo">
        <img class="hc-logo" :src="hcLogo" alt="holders club logo">
      </div>

      <div class="bottom">
        <div class="bottom-username">
          @<span>God2be</span>
        </div>
        <div class="bottom-tier">
          TIER 1
          MEMBER
        </div>
      </div>
    </div>

    <div class="why-panel__info">
      <div
        v-for="item in staticInfo"
        :key="item.title"
        class="q-expansion-item"
      >
        <div class="q-item__label q-pb-sm">
          {{ item.title }}
        </div>
        <div class="why-panel__info--desc" v-html="item.desc" />
      </div>
      <q-expansion-item
        v-for="item in expansionInfo"
        :key="item.title"
        dense
        dense-toggle
        expand-separator
        :label="item.title"
        :expand-icon="matAdd"
        :expanded-icon="matRemove"
      >
        <div class="why-panel__info--desc" v-html="item.desc" />
      </q-expansion-item>
    </div>
  </additional-panel>
</template>

<style lang="scss">
.why-panel {
  &__card {
    position: relative;
    max-width: 380px;
    width: 100%;
    min-height: 230px;
    height: 230px;
    background: url(~/assets/img/why-card.png) no-repeat center;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    .logos {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 50%;
      top: 46%;
      transform: translate(-50%, -50%);

      .jpool-logo {
        width: 80px;
        height: 23px;
        object-fit: contain;
        margin: 0 0 2px 70px;
      }

      .hc-logo {
        width: 202px;
        height: 56px;
        object-fit: contain;
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 20px 15px;
      color: $secondary;

      &-username {
        font-size: 17px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2px;

        span {
          color: #fff;
        }
      }

      &-tier {
        width: 64px;
        font-size: 13px;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.8px;
        text-align: right;
      }
    }
  }

  &__info {
    width: 100%;

    &--desc {
      color: $gray-4;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;

      a {
        color: $gray-4;
      }
    }

    .q-expansion-item {
      width: 100%;
      font-family: $font-secondary;
      padding-bottom: 15px;
      border-bottom: 1px solid #686868;
      margin-bottom: 28px;

      .q-separator {
        display: none;
      }

      .q-icon {
        color: $gray-3;
      }

      .q-item {
        padding: 0 6px 0 0;

        &__label {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
