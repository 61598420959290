<script lang="ts" setup>
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Line as lineChart } from 'vue-chartjs'
import priceIndicatorIcon from '~/assets/img/game/price-indicator.svg?raw'
import jpointIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice, roundToTwoSignificantDigits } from '~/utils'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler)

const { state } = useGameStore()

const dataSet = computed(() => {
  const data = [...state.exchangePrices]
  return data.slice(-10)
})

const chartData = computed(() => {
  return {
    labels: dataSet.value.map((d) => {
      const date = new Date(d.timestamp)
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${hour}:${String(minute).padStart(2, '0')}`
    }),
    datasets: [
      {
        backgroundColor: 'rgba(87, 110, 119, 0.52)',
        borderColor: '#5BBAC7',
        data: dataSet.value.map(d => d.price),
        fill: true,
      },
    ],
  }
})

const chartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label(context) {
          return `Price: ${context.raw}`
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#96B3C0',
      },
      grid: {
        color: '#375663',
      },
    },
    y: {
      position: 'right',
      ticks: {
        color: '#96B3C0',
      },
      grid: {
        color: '#375663',
      },
    },
  },
}

const priceStatus = computed(() => {
  const last = dataSet.value[dataSet.value.length - 1]
  const prev = dataSet.value[dataSet.value.length - 2]
  return Number(last?.price) < Number(prev?.price)
})

const currentPrice = computed(() => {
  const price = dataSet.value[dataSet.value.length - 1]?.price ?? 0
  const formatedPrice = roundToTwoSignificantDigits(price)
  return formatPrice(formatedPrice)
})
</script>

<template>
  <div class="exchange-chart">
    <div class="exchange-chart__detail">
      <div class="price-title">
        price for 1 barrel
      </div>
      <div class="price-amount">
        <img :src="jpointIcon" alt="jpoint icon">
        <div>
          {{ currentPrice }}
        </div>

        <i :class="{ 'price-down': priceStatus }" v-html="priceIndicatorIcon" />
      </div>
    </div>
    <lineChart :data="chartData" :options="chartOptions" />
  </div>
</template>
