<script lang="ts" setup>
import { matClose } from '@quasar/extras/material-icons'
import { useClipboard } from '@vueuse/core'
import { Notify } from 'quasar'
import botLogo from '~/assets/img/bot-logo.png'
import downloadIcon from '~/assets/img/icon/download.svg?raw'
import { BOT_NAME } from '~/config'
import { Analytics } from '~/utils/analytics'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close'])

const gameStore = useGameStore()

let QRCodeVue3

if (!import.meta.env.SSR) {
  import('qrcode-vue3').then((module) => {
    QRCodeVue3 = module.default
  })
}

const inviteLink = computed(() => {
  return `https://t.me/${BOT_NAME}?start=invite_${gameStore.state.gameUserData?.id}`
})

const { copy } = useClipboard({ source: inviteLink.value })

function close() {
  emit('close')
}

function download() {
  const btn = document.querySelector('.qr-dowload-btn') as HTMLElement
  btn?.click()
}

const inviteText = `Hi! ${gameStore.state.gameUserData?.username ?? 'User'} has invited you to join Pumpjack,`
  + ' a tap-to-earn game where you pump oil and earn rewards that unlock exclusive privileges'

const sendUrl = computed(() => {
  return `https://t.me/share/url?url=${encodeURIComponent(inviteLink.value)}&text=${encodeURIComponent(inviteText)}`
})

function copyHandler() {
  copy()
  Notify.create({
    message: 'Invited link copied',
    type: 'positive',
    position: 'top',
  })
  shareHandler('copy')
}

function shareHandler(action: string) {
  const label = action === 'copy' ? 'Click on copy button' : 'Click on share button'
  Analytics.track({
    event: 'Friends',
    label,
    userData: {
      id: gameStore.state.gameUserData?.id,
      username: gameStore.state.gameUserData?.username,
      telegramId: gameStore.state.gameUserData?.telegramId,
    } })
}
</script>

<template>
  <additional-panel class="game-invite" :show="show" @close="close">
    <div class="game-invite__title">
      Invite friends
    </div>

    <div class="qr-invite">
      <q-r-code-vue3
        :width="230"
        :height="230"
        :value="inviteLink"
        :qr-options="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
        :image-options="{ hideBackgroundDots: true, imageSize: 0.4, margin: 10 }"
        :dots-options="{
          type: 'dots',
          color: '#000',
        }"
        :background-options="{ color: '#ffffff' }"
        :corners-square-options="{ type: 'dot', color: '#000000' }"
        :corners-dot-options="{ type: undefined, color: '#000000' }"
        file-ext="png"
        myclass="qr-image"
        :download="true"
        download-button="qr-dowload-btn"
        :image="botLogo"
        :download-options="{ name: 'qr-code', extension: 'png' }"
      />

      <div class="qr-download" @click="download">
        <i v-html="downloadIcon" />
        Download QR
      </div>
    </div>

    <div class="game-invite__actions">
      <q-btn unelevated class="filled" :href="sendUrl" @click="shareHandler('share')">
        <i-app-send color="#8c9ca7" />
        Send
      </q-btn>
      <q-btn unelevated class="filled" @click="copyHandler">
        <i-app-copy color="#8c9ca7" />
        Copy
      </q-btn>

      <q-btn
        unelevated @click="close"
      >
        Close
      </q-btn>
    </div>
  </additional-panel>
</template>
