<script lang="ts" setup>
import gsap from 'gsap'
import { date } from 'quasar'
import barrelIcon from '~/assets/img/game/barrel.png'
import jpointsIcon from '~/assets/img/icon/j-points.svg'
import { formatPrice, roundToTwoSignificantDigits } from '~/utils'

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const gameStore = useGameStore()

const tweened = reactive({
  number: Number(gameStore.userPoints) || 0,
})

const timeStamp = Date.now()
const formattedString = date.formatDate(timeStamp, 'DD.MM.YYYY')

const dayToString = computed(() => {
  const day = new Date().getDay()
  return dayString[day]
})

function formatJpoints(jpoints: number) {
  const amount = jpoints ?? 0
  const formatedAmount = roundToTwoSignificantDigits(amount)
  return formatPrice(formatedAmount, 2, 2)
}

const barrel = computed(() => gameStore.gameConfig?.tower?.barrel ?? 42)
const allBarrels = computed(() => Math.floor(Number(gameStore.state.gameUserData?.barrels ?? 0) / barrel.value))

watch(() => gameStore.userPoints, (p) => {
  gsap.to(tweened, { duration: 1, number: Number(p) || 0 })
})
</script>

<template>
  <div class="exchange-top">
    <div class="exchange-top__left">
      <div class="title">
        Exchange
      </div>
      <div class="date">
        <span>{{ dayToString }},</span> {{ formattedString }}
      </div>
    </div>
    <div class="exchange-top__right">
      <div class="amount-data">
        <div class="jpoints">
          <img :src="jpointsIcon" alt="jpoints icon">
          <div class="jpoints-amount">
            {{ formatJpoints(tweened.number) }}
          </div>
        </div>
        <div class="jpoints barrel">
          <img :src="barrelIcon" alt="narrel icon">
          <div class="jpoints-amount">
            {{ formatPrice(allBarrels) }}
          </div>
        </div>
      </div>
      <game-user-avatar class="avatar" is-avatar />
    </div>
  </div>
</template>
