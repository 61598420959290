<script lang="ts" setup>
const { state } = useGameStore()
const { state: membershipState } = useMembershipStore()

const gameUser = computed(() => state.gameUserData)
const position = computed(() => state.leaderboardData?.user.position)
const showBtn = computed(() => !membershipState.userData)
</script>

<template>
  <div class="game-leaderboard">
    <div class="game-leaderboard__wrapper">
      <game-leaderboard-item
        v-if="gameUser && position"
        :user="gameUser"
        :position="position"
        :show-btn="showBtn"
        is-avatar
        class="leaderboard-game-user"
      />
      <game-leaderboard-item
        v-for="(user, idx) in state.leaderboardData?.leaderboard"
        :key="user.id"
        :user="user"
        :position="idx + 1"
        is-medal
      />
    </div>
  </div>
</template>

<style lang="scss">
.game-leaderboard {
  &__wrapper {
    padding: 20px;
  }

  .leaderboard-game-user {
    position: relative;
    padding-bottom: 32px;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 16px;
      background-color: $light-gray;
    }
  }
}
</style>
