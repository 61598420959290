<script lang="ts" setup>
import type { PropType } from 'vue'
import bronzeMedalIcon from '~/assets/img/game/bronze-medal.svg'
import goldMedalIcon from '~/assets/img/game/gold-medal.svg'
import silverMedalIcon from '~/assets/img/game/silver-medal.svg'
import jointIcon from '~/assets/img/icon/j-points.svg'
import hcMiniIcon from '~/assets/img/logo/hc-mini.svg?raw'
import { HOLDERS_CLUB_LINK } from '~/config'
import type { GameUserData } from '~/services'
import { formatPrice, normalizeUsername } from '~/utils'
import { Analytics } from '~/utils/analytics'

const props = defineProps({
  user: {
    type: Object as PropType<GameUserData>,
    required: true,
  },
  position: {
    type: Number,
    default: 0,
    required: true,
  },
  isMedal: {
    type: Boolean,
    default: false,
  },
  showBtn: {
    type: Boolean,
    default: false,
  },
  isAvatar: {
    type: Boolean,
    default: false,
  },
})

const gameStore = useGameStore()

const linkedUrl = computed(() => `${HOLDERS_CLUB_LINK}?telegramId=${gameStore.state.gameUserData?.telegramId}`)

function medalIcon(place: number) {
  switch (place) {
    case 1: return goldMedalIcon
    case 2: return silverMedalIcon
    case 3: return bronzeMedalIcon
  }
}

function linkedHandler() {
  Analytics.track({
    event: 'Connect Holders Club',
    userData: {
      id: gameStore.state.gameUserData?.id,
      username: gameStore.state.gameUserData?.username,
      telegramId: gameStore.state.gameUserData?.telegramId,
    } })
}

const userPoints = computed(() => {
  if (!props.user) {
    return 0
  }
  const allPoints = Number(props.user.points) + Number(props.user.referralPoints)
  return formatPrice(allPoints.toFixed(2), 2, 2)
})
</script>

<template>
  <div class="leaderboard-item">
    <div class="leaderboard-item-avatar">
      <game-user-avatar :username="user.username" :is-avatar="isAvatar" />
      <div v-if="user.userId" class="hc-indicator">
        <i v-html="hcMiniIcon" />
        <q-tooltip anchor="top middle" :offset="[0, 40]">
          Holders Club member
        </q-tooltip>
      </div>
    </div>
    <div class="leaderboard-item-data">
      <div class="leaderboard-item-data__username">
        {{ normalizeUsername(user?.username) }}
      </div>
      <div class="leaderboard-item-data__points">
        <img :src="jointIcon" alt="jpoint icon"> {{ userPoints }}
      </div>
    </div>
    <div v-if="showBtn" class="leaderboard-item-linked">
      <div class="text">
        <span>Not Linked</span> to the Club
      </div>
      <q-btn
        v-if="!gameStore.isLinked"
        unelevated target="_blank"
        :href="linkedUrl"
        @click="gameStore.isLinked = true, linkedHandler()"
      >
        link
      </q-btn>
      <q-btn
        v-else
        :loading="gameStore.loadingState.linkedLoading"
        unelevated
        target="_blank"
        class="refresh"
        @click="gameStore.checkLinkedUser"
      >
        refresh
      </q-btn>
    </div>
    <div v-if="!showBtn" class="leaderboard-item-place" :class="{ 'first-place': position === 1 }">
      <img v-if="position < 4 && isMedal" :src="medalIcon(position)" alt="">
      <span v-else>#{{ position }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.leaderboard-item {
  padding: 0 0 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: $font-secondary;

  &-avatar {
    position: relative;
    .home-top__avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hc-indicator {
      position: absolute;
      top: -4px;
      right: -9px;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;

      i {
        color: $secondary;
        display: flex;
        align-items: center;
        width: 16px;
        height: 16px;
      }
    }
  }

  &-linked {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;

    .text {
      color: $negative;
      font-size: 10px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 8px 0 0;

      span {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .q-btn {
      min-width: 71px;
      width: 71px;
      min-height: 31px;
      height: 31px;
      padding: 0;
      background-color: $secondary;
      color: #000 !important;
      font-family: $font-secondary;
      font-size: 13px;
      font-weight: 700;
    }

    .refresh {
      font-size: 11px;
    }
  }

  &-data {
    padding-left: 4px;
    overflow: hidden;

    &__username {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &__points {
      color: $secondary;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 7px;

      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
    }
  }

  &-place {
    margin-left: auto;
    width: 24px;
    height: 24px;

    img {
      width: 100%;
      object-fit: contain;
    }

    &.first-place {
      transform: scale(1.6);
      margin-bottom: -8px;
    }
  }
}
</style>
