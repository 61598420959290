let lastCall = 0
let timeoutId

export function dynamicThrottle(fn, delay) {
  return function () {
    const now = Date.now()
    const timeSinceLastCall = now - lastCall

    if (timeSinceLastCall >= delay) {
      fn()
      lastCall = now
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        fn()
        lastCall = Date.now()
      }, delay - timeSinceLastCall)
    }
  }
}

export function normalizeUsername(username: string) {
  return username.startsWith('anonymous_') ? 'anonymous' : username
}
