import { Notify } from 'quasar'
import discordIcon from '~/assets/img/icon/discord-icon.svg?raw'
import hcIcon from '~/assets/img/icon/hc-icon.svg?raw'
import solanaIcon from '~/assets/img/icon/solana-icon.svg?raw'
import telegramIcon from '~/assets/img/icon/telegram-icon.svg?raw'
import twitterIcon from '~/assets/img/icon/twitter-icon.svg?raw'
import youtubeIcon from '~/assets/img/icon/youtube-icon.svg?raw'
import type { ClaimedTask, GameTask } from '~/services'
import { useGameService } from '~/services'

export function useTasks() {
  const tasksStore = useTasksStore()
  const gameStore = useGameStore()

  const { claimTask } = useGameService()

  const state = reactive({
    claimed: false,
    claimIcon: false,
  })

  const router = useRouter()

  function sortTasks(a: GameTask, b: GameTask) {
    const now = Date.now()
    const aValidUntil = new Date(String(a.validUntil)).getTime()
    const bValidUntil = new Date(String(b.validUntil)).getTime()

    const aIsExpired = a.isTimeLimited && aValidUntil < now
    const bIsExpired = b.isTimeLimited && bValidUntil < now

    const aIsClaimed = tasksStore.state.claimedTasks.find(t => t.taskId === a.id)
    const bIsClaimed = tasksStore.state.claimedTasks.find(t => t.taskId === b.id)

    if (aIsExpired && bIsExpired) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }

    if (aIsExpired) {
      return 1
    }
    if (bIsExpired) {
      return -1
    }

    if (aIsClaimed && bIsClaimed) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }

    if (aIsClaimed) {
      return 1
    }
    if (bIsClaimed) {
      return -1
    }

    if (a.order !== b.order) {
      return a.order - b.order
    }

    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  }

  function taskIcon(icon: string) {
    switch (icon) {
      case 'twitter': return twitterIcon
      case 'telegram': return telegramIcon
      case 'holders_club': return hcIcon
      case 'discord': return discordIcon
      case 'albus': return ''
      case 'youtube': return youtubeIcon
      case 'solana': return solanaIcon
      default: return ''
    }
  }

  function isValid(validUntil?: Date) {
    if (!validUntil) {
      return false
    }
    return new Date(validUntil).getTime() > Date.now()
  }

  async function claimHandler(task: GameTask) {
    try {
      const claimedTaskId = await claimTask(gameStore.state.gameUserData!.id, task.id)
      await new Promise((resolve) => {
        tasksStore.state.loading = true
        setTimeout(() => {
          state.claimIcon = true
          state.claimed = false
          tasksStore.state.loading = false

          setTimeout(() => {
            tasksStore.state.startTaskId = undefined
            tasksStore.state.claimedTasks.push({
              taskId: claimedTaskId?.taskId,
              rewardPoints: task.rewardPoints } as ClaimedTask)

            if (gameStore.state.gameUserData) {
              const points = Number(gameStore.state.gameUserData.points) + Number(task.rewardPoints)
              gameStore.state.gameUserData.points = String(points)
            }

            tasksStore.state.isNotify = true
            resolve(null)
          }, 500)

          setTimeout(() => {
            tasksStore.state.isNotify = false
          }, 3000)
        }, 2000)
      })
    } catch (err) {
      Notify.create({
        // @ts-expect-error...
        message: String(err?.message || 'Task already claimed'),
        type: 'negative',
        position: 'top',
      })
    }
  }

  function getGameTaskDataAmountFromDesctiption(data: string) {
    return data.split(' ').find(t => Number(t))
  }

  async function checkGameTask(task: GameTask) {
    if (task.type === 'holders_club' && task.description?.includes('Become a member')) {
      const member = gameStore.state.gameUserData?.userId
      if (member) {
        state.claimed = true
      } else {
        router.replace({ query: { activeTab: 'holders club' } })
      }
      return
    }
    if (task.type === 'other' && task.title.toLowerCase() === 'game') {
      if (task.description?.toLowerCase().includes('earn')) {
        const data = getGameTaskDataAmountFromDesctiption(task.description)
        if (data) {
          const points = (Number(gameStore.state.gameUserData?.points) + Number(gameStore.state.gameUserData?.referralPoints)) || 0
          if (points >= Number(data)) {
            state.claimed = true
          } else {
            Notify.create({
              message: 'Not enough points',
              type: 'warning',
              position: 'top',
            })
          }
        }
        return
      }
      if (task.description?.toLowerCase().includes('invite')) {
        const data = getGameTaskDataAmountFromDesctiption(task.description)
        if (data) {
          const referrals = gameStore.state.referrals?.length || 0
          if (referrals >= Number(data)) {
            state.claimed = true
          } else {
            Notify.create({
              message: 'Not enough referrals',
              type: 'warning',
              position: 'top',
            })
          }
        }
      }
    }
  }

  async function startTask(task: GameTask) {
    try {
      tasksStore.state.loading = true
      if (!task) {
        return
      }
      if (task.isTimeLimited && task.validUntil) {
        if (!isValid(task.validUntil)) {
          return
        }
      }

      if (!state.claimed && task.needCheck) {
        await checkGameTask(task)
        return
      }

      tasksStore.state.startTaskId = task.id

      if (state.claimed) {
        await claimHandler(task)
        return
      }

      if (task.url) {
        const a = document.createElement('a')
        a.href = task?.url ?? ''
        a.target = '_blank'
        a.click()
      }

      await new Promise((resolve) => {
        setTimeout(() => {
          state.claimed = true
          resolve(null)
        }, 7000)
      })
    } finally {
      tasksStore.state.loading = false
    }
  }

  return {
    state,

    sortTasks,
    taskIcon,

    isValid,
    startTask,
  }
}
