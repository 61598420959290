import { Notify } from 'quasar'
import { FULL_SPEED_DURATION, MAX_TOWER_SPEED, MIN_TOWER_SPEED, TOWER_STOP_PER_SECOND } from '~/config'
import { useGameService } from '~/services'

function calculateTowerSpeed(value: number) {
  const minEndurance = 0
  const maxEndurance = 100
  const minValue = MIN_TOWER_SPEED
  const maxValue = MAX_TOWER_SPEED
  return minValue + ((value - minEndurance) * (maxValue - minValue)) / (maxEndurance - minEndurance)
}

export function useGame() {
  const gameStore = useGameStore()

  const {
    updateTowerData,
    updateGamePoints,
    sellBarrels } = useGameService()

  async function updateTowerState() {
    try {
      if (gameStore.state.gameUserData?.id) {
        await updateTowerData(gameStore.state.gameUserData.id, {
          heatLevel: gameStore.towerState.heatLevel,
          lastClickTime: gameStore.towerState.lastClickTime,
          speed: gameStore.towerState.speed,
          startFarmingAt: gameStore.towerState.startFarmingAt,
        })
      }
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: String(err),
        position: 'top',
      })
    }
  }

  // async function updateCurrentDayStatistic() {
  //   try {
  //     const currentDayStatistic = gameStore.state.gameStatistic[0]
  //     if (currentDayStatistic && Number(gameStore.state.gameUserData?.points)) {
  //       currentDayStatistic.amount = Number(gameStore.state.gameUserData?.points)
  //     }
  //   } catch (err) {
  //     Notify.create({
  //       type: 'negative',
  //       message: String(err),
  //       position: 'top',
  //     })
  //   }
  // }

  async function updateGameUserData() {
    try {
      gameStore.loadingState.sellLoading = true
      const farmingBarrels = gameStore.state.farmGallons - gameStore.state.savedGallons
      if (farmingBarrels === 0) {
        return
      }
      await updateGamePoints(gameStore.state.gameUserData?.id, { barrels: farmingBarrels })
      console.log(`Saved points: ${farmingBarrels}`)
      gameStore.state.savedGallons += farmingBarrels
      gameStore.state.gameUserData!.barrels = Number(gameStore.state.gameUserData!.barrels) + farmingBarrels
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: String(err),
        position: 'top',
      })
    } finally {
      gameStore.loadingState.sellLoading = false
    }
  }

  async function sellUserBarrels(barrels: number) {
    try {
      gameStore.loadingState.sellLoading = true
      const { updatedUser, lastSell } = await sellBarrels(gameStore.state.gameUserData?.id, { barrels })
      if (updatedUser) {
        gameStore.state.gameUserData = updatedUser
        if (lastSell) {
          gameStore.state.sellHistory.unshift(lastSell)
        }
      }
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: String(err),
        position: 'top',
      })
      throw new Error(String(err))
    } finally {
      gameStore.loadingState.sellLoading = false
    }
  }

  function calculateSpeed(clicks: number) {
    const now = Date.now()
    const timeElapsed = (now - gameStore.towerState.lastClickTime) / 1000
    const clicksPerSecond = clicks / timeElapsed

    const intensity = Math.min(100, (clicksPerSecond / 5) * 100)
    const targetSpeed = calculateTowerSpeed(intensity)

    const accelerationFactor = 1 / FULL_SPEED_DURATION

    if (targetSpeed > gameStore.towerState.speed) {
      const speedDifference = targetSpeed - gameStore.towerState.speed
      gameStore.towerState.speed += speedDifference * accelerationFactor
    } else {
      const speedDifference = gameStore.towerState.speed - targetSpeed
      gameStore.towerState.speed -= speedDifference * TOWER_STOP_PER_SECOND
    }
  }

  return {
    updateTowerState,
    calculateSpeed,

    sellUserBarrels,

    updateGameUserData,
  }
}
