<script lang="ts" setup>
import { capitalize } from 'lodash-es'

const tabs = ['history', 'leaderboard']
const tab = ref(tabs[0])
</script>

<template>
  <div class="exchange-tabs">
    <q-tabs v-model="tab">
      <q-tab v-for="t in tabs" :key="t" :name="t">
        {{ capitalize(t) }}
      </q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" class="exchange-tabs-panel" animated>
      <q-tab-panel name="history">
        <game-sell-history />
      </q-tab-panel>

      <q-tab-panel name="leaderboard">
        <game-leaderboard />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
