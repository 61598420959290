<script lang="ts" setup>
import jpointsIcon from '~/assets/img/icon/j-points.svg?raw'
import type { GameTask } from '~/services'
import { withZero } from '~/utils'

const props = defineProps({
  task: {
    type: Object as PropType<GameTask>,
    required: true,
  },
})

const taskStore = useTasksStore()
const { startTask, isValid, state } = useTasks()

const result = ref('00:00:00')

const claimedTasks = computed(() => taskStore.state.claimedTasks)
const alreadyClaimed = computed(() => !!claimedTasks.value.find(t => t.taskId === props.task.id))

let timeRemainingInterval
function getTimeRemaining() {
  const endTime = new Date(String(props.task?.validUntil)).getTime()
  const now = Date.now()
  const timeRemaining = endTime - now

  if (timeRemaining <= 0 || alreadyClaimed.value) {
    clearInterval(timeRemainingInterval)
    result.value = 'Ended'
    return
  }

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
  const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

  if (days > 0) {
    result.value = `${days}D ${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}`
  } else {
    result.value = `${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}`
  }
}

const isDisabled = computed(() => {
  const { startTaskId } = taskStore.state
  return (startTaskId && startTaskId !== props.task?.id) || !isValid(props.task?.validUntil)
})

const loading = computed(() => {
  return taskStore.state.startTaskId === props.task?.id && taskStore.state.loading
})

onMounted(() => {
  getTimeRemaining()

  timeRemainingInterval = setInterval(() => {
    getTimeRemaining()
  }, 1000)
})

onUnmounted(() => {
  clearInterval(timeRemainingInterval)
})
</script>

<template>
  <div class="tasks-cards__item" :class="{ 'claimed-disable': alreadyClaimed || result === 'Ended' }">
    <div class="item-reward">
      +{{ task?.rewardPoints }}
      <i v-html="jpointsIcon" />
    </div>
    <div class="item-info">
      {{ task?.description }}
    </div>

    <div class="item-action">
      <span v-if="alreadyClaimed">
        Claimed
      </span>
      <span v-else>
        {{ result }}
      </span>

      <task-claim-btn
        :claim-icon="state.claimIcon"
        :task-id="task?.id"
        :disabled="isDisabled"
        :loading="loading"
        :claimed="state.claimed"
        @start-task="startTask(task)"
      />
    </div>
  </div>
</template>
