export function formatPrice(price: number | string, minDigits = 0, maxDigits = 10) {
  const longPriceFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  })
  return longPriceFormatter.format(Number(price))
}

export function roundToTwoSignificantDigits(number: number | string): string {
  const str = number.toString()
  const [integer, decimals] = str.split('.')
  if (!decimals) {
    return String(integer)
  }
  const idx = Array.from(decimals)?.findIndex(d => d !== '0')
  let res = decimals.slice(0, idx + 1)
  const second = decimals.charAt(idx + 1)
  if (second && second !== '0') {
    res += second
  }
  return `${integer}.${res}`
}
