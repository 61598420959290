export async function getProfilePhoto(id: string) {
  const token = import.meta.env.VITE_TELEGRAM_BOT_TOKEN
  const photosUrl = `https://api.telegram.org/bot${token}/getUserProfilePhotos`
  const fileUrl = `https://api.telegram.org/bot${token}/getFile`

  try {
    const response = await fetch(`${photosUrl}?user_id=${id}`)
    const data = await response.json()

    if (data && data.result && data.result.photos.length > 0) {
      const photo = data.result.photos[0][0]
      const fileId = photo.file_id

      const fileResponse = await fetch(`${fileUrl}?file_id=${fileId}`)
      const fileData = await fileResponse.json()

      if (fileData && fileData.result) {
        const filePath = fileData.result.file_path
        return `https://api.telegram.org/file/bot${token}/${filePath}`
      }
    }
  } catch (error) {
    console.error('Telegram avatar error:', error)
  }
}
