<script lang="ts" setup>
import { date } from 'quasar'
import jpointsIcon from '~/assets/img/icon/j-points.svg'
import { BOT_NAME } from '~/config'
import { formatPrice, normalizeUsername } from '~/utils'
import { Analytics } from '~/utils/analytics'

const gameStore = useGameStore()

const referrals = computed(() => gameStore.preparedReferrals?.length ?? 0)

const isInvite = ref(false)

const totalPoints = computed(() => {
  if (referrals.value === 0) {
    return 0
  }
  return gameStore.preparedReferrals.reduce((acc, el) => acc + Number(el.points + el.bonusPoints), 0)
})

function formatDate(timeStamp: string) {
  return date.formatDate(new Date(timeStamp), 'DD.MM.YYYY')
}

const inviteLink = computed(() => {
  return `https://t.me/${BOT_NAME}?start=invite_${gameStore.state.gameUserData?.id}`
})

const inviteText = `Hi! ${gameStore.state.gameUserData?.username ?? 'User'} has invited you to join Pumpjack,`
  + ' a tap-to-earn game where you pump oil and earn rewards that unlock exclusive privileges'

const sendUrl = computed(() => {
  return `https://t.me/share/url?url=${encodeURIComponent(inviteLink.value)}&text=${encodeURIComponent(inviteText)}`
})

function shareHandler() {
  const label = 'Click on share button'
  Analytics.track({
    event: 'Friends',
    label,
    userData: {
      id: gameStore.state.gameUserData?.id,
      username: gameStore.state.gameUserData?.username,
      telegramId: gameStore.state.gameUserData?.telegramId,
    } })
}
</script>

<template>
  <div class="game-friends">
    <div class="game-friends__title">
      Invite friends

      <i-app-qr-icon class="qr-icon" @click="isInvite = true" />
    </div>

    <div class="game-friends__desc">
      Get <strong>10%</strong> of all JPoints that your friends will earn in the Game.
    </div>

    <div class="game-friends__stats">
      <div class="game-friends__stats-item">
        {{ referrals }}
        <span>Friends</span>
      </div>
      <div class="game-friends__stats-item">
        {{ formatPrice(Number(totalPoints), 2, 2) }}
        <span>Total JP earned</span>
      </div>
    </div>

    <div v-if="referrals === 0" class="no-friends">
      No friends yet  =(
    </div>

    <q-virtual-scroll
      v-else
      v-slot="{ item, index }"
      style="max-height: 600px;"
      :items="gameStore.preparedReferrals"
      separator
      class="game-friends__list"
    >
      <q-item
        :key="index"
        dense
      >
        <q-item-section>
          <q-item-label>
            <div class="game-friends__referral">
              <game-user-avatar :username="item.username" />

              <div class="game-friends__referral-info">
                {{ normalizeUsername(item?.username) }}

                <span>Joined on {{ formatDate(item.createdAt) }}</span>
              </div>

              <div class="game-friends__referral-points">
                <span>{{ formatPrice(item.points + item.bonusPoints, 2, 2) }}</span>

                <img :src="jpointsIcon" alt="jpoints icon">
              </div>
            </div>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-virtual-scroll>

    <q-btn unelevated class="invite-btn" :href="sendUrl" @click="shareHandler">
      Invite
    </q-btn>

    <game-invite :show="isInvite" @close="isInvite = false" />
  </div>
</template>
