import { defineStore } from 'pinia'
import { useGameService } from '~/services'
import type { ClaimedTask, GameTask } from '~/services'

export const useTasksStore = defineStore('tasks', () => {
  const {
    getTasks,
    getClaimedTasks,
  } = useGameService()

  const state = reactive<TasksState>({
    tasks: [],
    startTaskId: undefined,
    claimedTasks: [],
    loading: false,
    isNotify: false,
  })

  const gameStore = useGameStore()

  async function loadTasks() {
    try {
      const data = await getTasks() ?? []
      state.tasks = data
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadClaimedTasks(id: string) {
    try {
      const data = await getClaimedTasks(id) ?? []
      state.claimedTasks = data
    } catch (err) {
      throw new Error(String(err))
    }
  }

  const preparedTasks = computed(() => {
    const allTasks = state.tasks?.sort((a, b) => {
      if (a.order !== b.order) {
        return a.order - b.order
      }

      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
    return allTasks.reduce((acc: { [key: string]: GameTask[] }, el) => {
      if (el.isTimeLimited) {
        acc.card?.push(el)
        return acc
      }
      if (acc[el.type]) {
        acc[el.type]?.push(el)
      }

      return acc
    }, {
      card: [],
      social: [],
      onchain: [],
      other: [],
      holders_club: [],
    })
  })

  watch(() => gameStore.state.gameUserData?.id, async (id) => {
    if (id) {
      Promise.allSettled([
        loadTasks(),
        loadClaimedTasks(id)])
    }
  }, { immediate: true })

  return {
    state,
    preparedTasks,

    loadTasks,
    loadClaimedTasks,
  }
})

type TasksState = {
  tasks: GameTask[]
  startTaskId?: string
  claimedTasks: ClaimedTask[]
  loading: boolean
  isNotify: boolean
}
